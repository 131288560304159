import { documents as sidDocuments } from '../../../../../../../store/modules/securitize-id/options';

const { categories: { documentTypeIndividual } } = sidDocuments;

export default {
  categories: {
    documentTypeIndividual,
    documentsTypeEntity: {
      'revocable-trust': [
        { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
        { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
      ],
      'irrevocable-trust': [
        { value: { docCategory: 'signer-entity', docType: 'document-or-certificate-of-trust' }, text: 'Certificate of Trust' },
        { value: { docCategory: 'signer-entity', docType: 'list-of-trustees' }, text: 'List of Trustees' },
      ],
      'limited-partnership/general-partnership': [
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'signer-entity', docType: 'partnership-agreement' }, text: 'Partnership Agreement' },
        { value: { docCategory: 'signer-entity', docType: 'lp-list-and-authorised-signers-list' }, text: 'LP list and authorized signers list' },
      ],
      llc: [
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'signer-entity', docType: 'articles-of-organization' }, text: 'Articles of Organization' },
        { value: { docCategory: 'signer-entity', docType: 'operating-agreement' }, text: 'Operating Agreement' },
        { value: { docCategory: 'signer-entity', docType: 'members-and-authorised-signers-list' }, text: 'Members\'s list and authorized signers list' },
      ],
      corporation: [
        { value: { docCategory: 'signer-entity', docType: 'by-laws' }, text: 'By-laws' },
        { value: { docCategory: 'signer-entity', docType: 'certificate-of-formation' }, text: 'Certificate of Formation' },
        { value: { docCategory: 'signer-entity', docType: 'articles-of-corporation' }, text: 'Articles of Corporation' },
        { value: { docCategory: 'signer-entity', docType: 'shareholders-list-and-authorised-signers-list' }, text: 'Shareholder\'s list and authorized signers list' },
      ],
      other: [],
    },
  },
  fields: [
    { key: 'image', label: 'Thumbnail' },
    { key: 'docType', label: 'Document Type', tdClass: 'align-middle' },
    { key: 'status', label: 'Status', tdClass: 'align-middle' },
    { key: 'createdAt', label: 'Creation Date', tdClass: 'align-middle' },
    {
      key: 'actions', label: '', i18nKey: 'investorDetails.documents.label.table.empty', tdClass: 'text-nowrap d-inline float-right',
    },
  ],
  statuses: [
    {
      text: 'Pending',
      value: 'pending',
    },
    {
      text: 'Verified',
      value: 'verified',
    },
    {
      text: 'Not verified',
      value: 'not-verified',
    },
  ],
};
